
import { Factcheck } from "@/types";
import { Component, Vue } from "vue-property-decorator";

import * as FactCheckService from '@/services/FactCheckService';

@Component
export default class Index extends Vue {
  concepts: any[] = []

  async mounted(){
    this.concepts = await FactCheckService.GetConcepts();
  }

  async deleteConcept(conceptId: string){
    await FactCheckService.DeleteConcept(conceptId);
    this.concepts = await FactCheckService.GetConcepts();
  }
}
